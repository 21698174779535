.booking-estimation {
    position: relative;
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding-top: calc(40px + 64px);
}

@media (max-width: 1000px) {
    .booking-estimation {
        padding-top: calc(20px + 48px);
    }
}

.booking-wrap {
    display: flex;
    gap: 40px;
    flex-direction: column;
}

.booking-wrap2 {
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.booking-box {
    background-color: var(--white);
    border-radius: var(--border-radius);
    margin-bottom: 500px;
    width: 100%;
    max-width: 1000px;
    padding: 0 50px;
}

@media (max-width: 1000px) {
    .booking-box {
        padding: 0 25px;
        margin-bottom: 300px;
    }
}

/** booking-item**/
.booking-form,
.confirm-booking-wrap {
    display: flex;
    gap: 40px;
    width: 100%;
}

.booking-form,
.confirm-booking-wrap2 {
    display: flex;
    gap: 15px;
    width: 100%;
}

.booking-select-list,
.booking-form-list,
.confirm-booking-content {
    width: calc(100% - 328px);
}

.confirm-booking-content2 {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.booking-form-list {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.booking-form-summary {
    width: 328px;
}

.booking-form-summary {
    width: 328px;
    position: sticky;
    /* right: 780px; */
}

.booking-form-summary2 {
    width: 328px;
}

.booking-form-summary2 {
    width: 328px;
    position: sticky;
    /* right: 780px; */
}

.confirm-booking-wrap2 {
    flex-direction: column;
}

.booking-form-summary2 {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 30px 25px 50px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 6.25%);
}

.booking-form-summary2>div {
    width: 1000px;
    text-align: center;
}


@media (max-width: 1000px) {

    .booking-form,
    .confirm-booking-wrap {
        flex-direction: column;
    }



    .booking-select-list,
    .booking-form-list,
    .confirm-booking-content {
        width: 100%;
    }

    .booking-form-summary {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        padding: 0 25px 50px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 6.25%);
    }
}

.booking-form-summary .list-style {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--light-grey-1);
}

.booking-item-list,
.confirm-booking-content {
    display: flex;
    flex-direction: column;
}

.booking-item-list {
    gap: 20px;
}

.booking-form-item {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.booking-item-list.row {
    flex-direction: row;
    flex-wrap: wrap;
}

@media (max-width: 1000px) {
    .booking-item-list.row {
        gap: 10px;
    }
}

.booking-item-list.row li {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
}

.booking-item {
    width: 100%;
    border-radius: var(--border-radius);
    border: 1px solid var(--line);
    padding: 41px 40px;
}

.booking-item.selected {
    padding: 40px 40px;
}

@media (max-width: 1000px) {
    .booking-item-list.row li {
        width: calc(50% - 5px);
        flex-basis: calc(50% - 5px);
    }

    .booking-item {
        padding: 21px 20px;
    }

    .booking-item.selected {
        padding: 20px 20px;
    }
}

@media (min-width: 1000px) {
    .booking-item:hover {
        border: 1px solid var(--grey-1);
    }
}

.booking-item.selected {
    border: 2px solid var(--blue);
}

.booking-item .subtitle {
    margin-bottom: 8px;
    line-height: 1.2;
}

.booking-item .title {
    margin-bottom: 40px;
}


.booking-item-list input {
    display: none;
}

.price-set {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.booking-description {
    margin-top: 40px;
}

.file-form-set {
    display: flex;
    gap: 16px;
}

@media (max-width: 1000px) {
    .file-form-set {
        flex-direction: column;
    }

    .booking-description {
        margin-top: 20px;
    }
}

.file-form-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


/*total amount*/
.total-amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

/* address input */
.address-input {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.reservation-item-list .address-input {
    gap: 8px;
}

.input-wrap {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

/* input wrap > button */
.input-wrap.input-with-button .input {
    width: calc(100% - 89px);
}

/*.input-wrap.select-address{*/
/*    align-items: center;*/
/*}*/

.input-wrap.select-address .input {
    width: calc(100% - 76px - 76px - 8px - 8px);
    flex-basis: calc(100% - 76px - 76px - 8px - 8px);
}

.input-wrap.select-address .button-small {
    width: 76px !important;
    flex-basis: 76px;
    padding: 14px 12px;
}

.reservation-item .input-wrap {
    gap: 8px;
}

.address-input .input-wrap .input {
    width: calc(100% - 105px);
}

.reservation-item .address-input .input-wrap .input {
    width: calc(100% - 178px);
}

@media (max-width: 1000px) {
    .address-input .input-wrap .input {
        width: calc(100% - 76px - 8px);
    }

    .input-wrap {
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }

    .address-input .input-wrap .button-small,
    .input-with-button .button-small {
        width: 76px !important;
        flex-basis: 76px;
        padding: 16px 12px;
    }

    .input-with-button .input {
        width: calc(100% - 76px - 8px);
        flex-basis: calc(100% - 76px - 8px);
    }

}

.contract-slide {
    height: 700px;
    margin-bottom: 20px;
    /* background-color: var(--bg); */
}

@media (max-width: 1000px) {
    .contract-slide {
        margin-bottom: 0;
    }
}

.contract-confirm-controls {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.contact-slide-controls {
    display: flex;
    align-items: center;
    gap: 24px;
}

@media (max-width: 1000px) {
    .contact-slide-controls {
        gap: 9px;
    }
}

.file-name {
    max-width: 100px;
}




/************ radio custom *************/
.custom-radio-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

}

.custom-radio {
    width: 20px;
    height: 20px;
    border: 1px solid #7C7C7C;
    border-radius: 50%;
    position: relative;
    margin-right: 8px;
}

.circle {
    width: 0;
    height: 0;
    background-color: #1eafd2;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    /* transition: all 0.3s ease; */
}

.custom-radio.active {


    border: 2px solid #1eafd2;

}

.custom-radio.active .circle {
    width: 12px;
    height: 12px;
    margin-top: -6px;
    margin-left: -6px;

    border: 2px solid #1eafd2;

}

.custom-label {
    margin: 0;
    font-weight: 600;
    color: #7C7C7C;
    font-size: var(--title-size-small)
}


.rr-checkbox {
    display: flex;
    flex-direction: row;
    gap: 10px;
}




.booking-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center;
    width: 100%;
    height: 100%; */
}

.booking-login .title {
    margin: 30px 0px 30px 0px;
}

.booking-login .title span {
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 40px;
}

.booking-login .title .bold {
    font-weight: bold;
}